<template>
  <v-row class="conversation-container">
    <v-col class="user-info" cols="12">
      <v-card class="user-info" color="blue">
        <v-toolbar flat color="blue">
          <v-btn icon="mdi-account"></v-btn>
          <v-toolbar-title class="font-weight-light text-sm-h6" cols="12">
            {{ user ? user['name'] : 'username' }} {{ user ? (user['name'] != user['phone_number'] && !isNumeric(user['name']) ? ' - ' + user['phone_number'] : '') : '' }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
    </v-col>
    <v-col class="messages" cols="12">
      <div v-if="user && admin" ref="list" id="msg-window" @scroll="handleScroll" class="">
        <div class="msg-container" v-for="msg in user['messages']" :id="msg._id" :key="msg._id">
          <div class="chat" :class="{
            'chat-u': admin ? msg.from == admin['id'] : false,
            'chat-f': to ? msg.from == to : false,
          }">
            <div v-if="msg.parent" @click="scrollToSectionHandler(msg.parent._id)" class="parent-msg-content" :class="{
              'reply-u': admin ? msg.from == admin['id'] : false,
              'reply-f': to ? msg.from == to : false,
            }">
              <h5 class="reply-section-sender">
                {{
                  msg.parent.from == admin?.id
                  ? 'You'
                  : msg.parent.from == to
                    ? user
                      ? user['name']
                      : 'username'
                    : 'user'
                }}
              </h5>

              <!-- <p style="word-wrap: break-word">{{ msg.parent.content }}</p> -->
              <p style="word-wrap: break-word;" v-html="formatMessage(msg.parent.content)"></p>

            </div>
            <div v-if="msg.type == 'text'">
              <!-- <p style="word-wrap: break-word">{{ msg.content }}</p> -->
              <p style="word-wrap: break-word;" v-html="formatMessage(msg.content)"></p>
            </div>
            <div v-else-if="msg.type == 'image'">
              <!-- <img width="150" height="100" :src="baseUrl + '/' + msg.content" /> -->

              <v-img width="150" height="100" :src="msg.content">
                <v-btn class="image-pop-up" @click.stop="onClickButton(msg.content)">
                  <v-icon icon="mdi-eye-outline" />
                </v-btn>
              </v-img>
            </div>
            <div v-else-if="msg.type == 'audio'">
              <audio controls style="width: 200px">
                <source :src="msg.content" type="audio/ogg" />

                Your browser does not support the audio element.
              </audio>
            </div>
            <div v-else-if="msg.type == 'video'">
              <video width="320" height="240" controls>
                <source :src="msg.content" type="video/mp4">
                <source :src="msg.content" type="video/ogg">
                Your browser does not support the video tag.
            </video>
            </div>
            <div v-else-if="msg.type == 'document'">
            
              <a class="document-style" target="_blank" @click.stop="handleDocument(msg.content)" :src="msg.content">document</a>
            
             
            </div>
            <div v-else>
              <!-- <p style="word-wrap: break-word">{{ msg.content }}</p> -->
              <p style="word-wrap: break-word;" v-html="formatMessage(msg.content)"></p>

            </div>

            <div class="msg-status-container">
              <div v-if="platform ? platform === 'whatsapp' : false" class="reply-btn" @click="replyHandler(msg)">
                Reply
              </div>

              <span class="info-container" v-if="msg.is_scheduled">Scheduled</span>

              <span class="info-container">{{ momentFilter(msg.createdAt) }}</span>

              <span class="status">
                <v-icon class="faild" v-if="msg.status === 'failed'" :title="msg.statusTitle"
                  icon="mdi-information"></v-icon>
                <v-icon v-else-if="msg.status === 'pending'" title="pending" icon="mdi-clock-time-three-outline"></v-icon>

                <v-icon v-else-if="msg.status === 'sent'" :title="msg.statusTitle" icon="mdi-check"></v-icon>
                <v-icon v-else-if="msg.status === 'delivered'" :title="msg.statusTitle" icon="mdi-check-all"></v-icon>
                <v-icon class="read" v-else-if="msg.status === 'read'" :title="msg.statusTitle"
                  icon="mdi-check-all"></v-icon>
              </span>
            </div>
          </div>

          <div class="clr"></div>
        </div>

        <v-btn v-if="isDown" class="down-btn" @click="downHandler">
          <v-icon icon="mdi-chevron-double-down" />
        </v-btn>

        <v-col v-if="isReply" class="reply-section">
          <div class="reply-section-container">
            <div class="reply-section-action">
              <span @click="closeReplyMsgHandler">X</span>
            </div>

            <div class="reply-section-content">
              <h5 class="reply-section-sender">
                {{
                  replyMsgParent.from == admin['id']
                  ? 'You'
                  : replyMsgParent.from == to
                    ? user
                      ? user['name']
                      : 'username'
                    : 'user'
                }}
              </h5>

              <!-- <p>{{ replyMsgParent.content }}</p> -->
              <p style="word-wrap: break-word;" v-html="formatMessage(replyMsgParent.content )"></p>

            </div>
          </div>
        </v-col>
      </div>
      <div v-else>
        <p class="text-center">No conversation</p>
      </div>

      <div class="text-part">
        <v-row class="columns ma-0">
          <v-col class="pa-0">
            <v-form>
              <!-- <v-container> -->
              <v-row class="ma-0">
                <v-col cols="12 pa-2">
                  <v-textarea min-width="20" row-height="15" rows="2"  v-model="message"  clearable label="Message" type="text" variant="outlined">
                    <!-- <template v-slot:prepend> </template> -->

                    <template v-slot:append-inner>
                      <v-fade-transition leave-absolute>
                        <v-progress-circular v-if="loading" color="info" indeterminate size="24"></v-progress-circular>
                      </v-fade-transition>
                    </template>

                    <template v-slot:append>
                      <div class="d-flex flex-column justify-content-center" style="align-items: center;">
                        <v-btn icon="$vuetify" color="primary" size="large" variant="text" @click="sendMsg">
                          Send
                        </v-btn>

                        <div class="d-flex justify-content-center">
                          <!-- <v-btn color="primary" @click="dialog = true"> -->
                          <v-icon title="select attachments" @click="dialog = true" icon="mdi-paperclip"></v-icon>

                          <v-badge class="d-none d-sm-flex" dot :color="is_scheduled ? 'error' : 'success'">
                            <v-icon title="schedule message" @click="scheduleDialog = true" icon="mdi-clock"></v-icon>
                          </v-badge>
                          <!-- </v-btn> -->

                          <v-badge class="d-none d-sm-flex" dot :color="is_template ? 'error' : 'success'">
                            <v-icon title="select template" icon="mdi-account-file-text" @click="openSelect"></v-icon>
                          </v-badge>

                          <v-icon title="clear template" icon="mdi-close-circle" @click="clearSelect"></v-icon>

                          <v-select ref="templateSelect" v-show="false" min-width="100" max-width="100"
                            update:modelValue="true" clearable :menu-props="{ maxHeight: 'unset' }" chips label=""
                            :items="Object.keys(this.templates)" v-model="template"
                            @update:modelValue="templateChanged"></v-select>
                        </div>
                      </div>



                    </template>
                   
                  </v-textarea>
                </v-col>
              </v-row>
              <!-- </v-container> -->
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>

  <template>
    <div class="text-center">
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent width="1024">
            <v-card>
              <v-card-title>
                <span class="text-h5">Select attachments</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-file-input v-model="files" color="deep-purple-accent-4" counter label="File input"
                      :rules="rules"
                      :accept="validAttachmentsTypes"
                        placeholder="Select your files" prepend-icon="mdi-paperclip" variant="outlined" :show-size="1000">
                        <template v-slot:selection="{ fileNames }">
                          <template v-for="(fileName, index) in fileNames" :key="fileName">
                            <v-chip v-if="index < 2" color="deep-purple-accent-4" label size="small" class="me-2">
                              {{ fileName }}
                            </v-chip>

                            <span v-else-if="index === 2" class="text-overline text-grey-darken-3 mx-2">
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*you can select images, videos and files</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="dialog = false"> Close </v-btn>
                <v-btn color="blue-darken-1" variant="text"  @keypress.enter="sendFilesHandler" @click="sendFilesHandler"> Send </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="image_pop_up" persistent class="test" width="1024">
            <v-card style="display: flex; justify-content: center; align-items: center">
              <v-card-title>
                <span class="text-h5">Image</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <img width="400" height="225" :src="image_path" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="image_pop_up = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
  </template>
  <!-- schedules -->
  <template>
    <div class="text-center">
      <template>
        <v-row justify="center">
          <v-dialog v-model="scheduleDialog" persistent width="1024">
            <v-card>
              <v-card-title>
                <span class="text-h5">Select Time to schdule your message</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                     
                      <input
                        id="party"
                        type="datetime-local"
                        name="schedule at"
                        :min="scheduleAt"
                        v-model="scheduleAt"
                         />
                    
                    </v-col>
                  </v-row>
                </v-container>
                
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="scheduleDialog = false"> Close </v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="scheduleDialog = false; is_scheduled = false; scheduleAt = momentTZ().format('YYYY-MM-DDTHH:mm')"> Reset </v-btn>

                <v-btn color="blue-darken-1" variant="text"  @keypress.enter="scheduleDialog = false; is_scheduled = true" @click="scheduleDialog = false; is_scheduled = true"> Schedule </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
  </template>
</template>

<style scoped>
.conversation-container {
  width: 100%;
  height: 100%;
  padding: 20px 10px;
}

.down-btn {
  border-radius: 50%;
  height: 45px !important;
  min-width: 45px !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* padding: 0; */
  /* margin: auto; */
  /* box-shadow: none; */
  /* border: 1px solid #CCC; */

  position: fixed;
  right: 13%;
  bottom: 23%;
}

.info-container {
  font-size: 10px;
  font-weight: 500;
}

.parent-msg-content {
  padding: 10px;
  border-left: 4px solid #2196f3;
  border-radius: 4px;
  width: 130px;
  cursor: pointer;
}

.reply-u {
  background-color: #01636d;
}

.reply-f {
  background-color: #c7c1c1;
}

.parent-msg-content p {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  width: 130px;
}

.msg-container {
  margin-bottom: 20px;
}

.reply-btn {
  position: absolute;
  bottom: -19px;
  color: black;
  left: 0px;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
}

.reply-section-sender {
  color: #2196f3;
}

.messages-container {
  height: 100%;
}

#msg-window {
  height: 60vh;
    border: 1px solid #cccc;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px 35px 10px;
}

.user-info {
  /* height: 10%; */
  margin: 0;
  padding: 0;
}

.msg-status-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 10px;
}

.status {
  font-size: 14px;
}

.status .read {
  color: #494174;
}

.status .faild {
  color: #e94944;
}

.messages {
  height: 95%;
  overflow-y: auto;
  padding: 10px;
  margin-top: 10px;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
}


.mycontainer {
  height: 100% !important;
  max-width: 400px !important;
  margin: 50px auto !important;
  padding: 0 10px 0 10px 0 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-bottom: 100px !important;
}

.chat {
  position: relative !important;
  border-radius: 0.4em !important;
  padding: 10px !important;
  margin-top: 20px !important;
}

.chat-u {
  background: #00aabb !important;
  color: #fff !important;
  max-width: 80% !important;
  float: right !important;
}

.chat-f {
  background: #dddddd !important;
  max-width: 80% !important;
  float: left !important;
}

.chat-u:after {
  content: '' !important;
  position: absolute !important;
  right: 0 !important;
  top: 50% !important;
  width: 0 !important;
  height: 0 !important;
  border: 4px solid transparent !important;
  border-left-color: #00aabb !important;
  border-right: 0 !important;
  margin-top: -4px !important;
  margin-right: -4px !important;
}

.chat-f:after {
  content: '' !important;
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  width: 0 !important;
  height: 0 !important;
  border: 4px solid transparent !important;
  border-right-color: #dddddd !important;
  border-left: 0 !important;
  margin-top: -4px !important;
  margin-left: -4px !important;
}

.clr {
  clear: both !important;
}

.text-part {
  position: absolute !important;
  bottom: 0 !important;
  /* padding-bottom: 20px !important; */
  background: rgb(238, 238, 238) !important;
  right: 0 !important;
  width: 100%;

  /* margin-top: 10px !important; */
  /* width: 100% !important;
  height: 20% !important; */
}

.text-part .columns {
  margin: 5px 30px !important;
}

.text-part input {
  /* width: 300px; */

  background-color: #ffffffff;
  width: 50% !important;
}

.reply-section {
  position: absolute !important;
  bottom: 100px !important;
  padding-bottom: 20px !important;
  background: rgb(238, 238, 238) !important;
  left: 0 !important;
  margin-top: 10px !important;
  width: 100% !important;
  height: 16% !important;
  border-radius: 20px 20px 0 0 !important;
}

.reply-section-container {
  position: relative;
}

.reply-section-action {
  position: absolute;
  right: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  /* border: 1px solid #fbfafa; */
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.reply-section-content {
  background-color: #fbfafa;
  padding: 10px;
  border-left: 4px solid #2196f3;
  border-radius: 4px;
}

.image-pop-up {
  position: relative;
  left: 59px;
  top: 32px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: none; */
  padding: 0;
  background-color: #cccccc;
  color: #ee4a23;
}
.document-style{
  color: #121253;
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '../stores/user'
import { useTemplateStore } from '../stores/template'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import momentTZ from 'moment-timezone';

export default {
  name: 'ConversationView',
  components: {},
  data: () => ({
    id: null,
    platform: 'whatsapp',
    files: [],
    dialog: false,
    scheduleDialog: false,
    scheduleAt: momentTZ().format('YYYY-MM-DDTHH:mm'),
    is_scheduled: false,
    isScroll: false,
    isFristScroll: false,
    image_pop_up: false,
    image_path: '',
    isDown: false,
    pageNumber: 1,
    platforms: ['whatsapp', 'instagram'],
    hasSaved: false,
    isEditing: null,
    message: '',
    userName: '',
    loading: false,

    to: null,
    type: null,
    isReply: false,
    replyMsgParent: {},
    baseUrl: process.env.VUE_APP_BACK_END_BASE_URL,
    // apiVersion : import.meta.env.VITE_BACK_END_BASE_API_VERSION,

    validAttachmentsTypes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/wspolicy+xml",
      "application/x-compress",
      "application/zip",
      "application/postscript",
      "application/x-aim",
      "application/x-bcpio",
      "application/octet-stream",
      "text/html",
      "application/x-cdf",
      "application/pkix-cert",
      "application/java",
      "application/x-cpio",
      "application/x-csh",
      "text/css",
      "application/msword",
      "application/x-wais-source",
      "application/x-netcdf",
      "application/oda",
      "application/vnd.oasis.opendocument.database",
      "application/vnd.oasis.opendocument.chart",
      "application/vnd.oasis.opendocument.formula",
      "application/vnd.oasis.opendocument.graphics",
      "application/vnd.oasis.opendocument.image",
      "application/vnd.oasis.opendocument.text-master",
      "application/vnd.oasis.opendocument.presentation",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.oasis.opendocument.graphics-template",
      "application/vnd.oasis.opendocument.text-web",
      "application/vnd.oasis.opendocument.presentation-template",
      "application/vnd.oasis.opendocument.spreadsheet-template",
      "application/vnd.oasis.opendocument.text-template",
      "application/ogg",
      "application/xslt+xml",
      "application/vnd.mozilla.xul+xml",
      "application/vnd.visio",
      "text/vnd.wap.wml",
      "application/vnd.wap.wmlc",
      "text/vnd.wap.wm",
      "application/vnd.wap.wmlscriptc",
      "application/font-woff",
      "application/font-woff2",
      "application/xml-dtd",
      "application/x-dvi",
      "application/vnd.ms-fontobject",
      "text/x-setext",
      "application/x-gtar",
      "application/x-gzip",
      "application/x-hdf",
      "application/mac-binhex40",
      "text/x-component",
      "text/vnd.sun.j2me.app-descriptor",
      "application/java-archive",
      "text/x-java-source",
      "application/x-java-jnlp-file",
      "application/javascript",
      "text/plain",
      "application/json",
      "application/x-latex",
      "application/x-font-opentype",
      "application/annodex",
      "application/xspf+xml",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.rn-realmedia",
      "application/rtf",
      "text/richtext",
      "application/font-sfnt",
      "application/x-sh",
      "application/x-shar",
      "application/x-stuffit",
      "application/x-sv4cpio",
      "application/x-sv4crc",
      "application/x-shockwave-flash",
      "application/x-tar",
      "application/x-tcl",
      "application/x-tex",
      "application/x-texinfo",
      "text/tab-separated-values",
      "application/x-font-ttf",
      "application/x-ustar",
      "application/voicexml+xml",
      "application/xhtml+xml",
      "application/vnd.ms-excel",
      "application/xml",
      "text/troff",
      "application/mathml+xml",
      "application/x-mif",
      "audio/aac",
      "audio/mp4",
      "audio/amr",
      "audio/mpeg",
      "audio/ogg; codecs=opus",
      "video/mp4",
      "video/3gpp",
      "image/jpeg",
      "image/png"
    ],
    validDocumentTypes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/wspolicy+xml",
      "application/x-compress",
      "application/zip",
      "application/postscript",
      "application/x-aim",
      "application/x-bcpio",
      "application/octet-stream",
      "text/html",
      "application/x-cdf",
      "application/pkix-cert",
      "application/java",
      "application/x-cpio",
      "application/x-csh",
      "text/css",
      "application/msword",
      "application/x-wais-source",
      "application/x-netcdf",
      "application/oda",
      "application/vnd.oasis.opendocument.database",
      "application/vnd.oasis.opendocument.chart",
      "application/vnd.oasis.opendocument.formula",
      "application/vnd.oasis.opendocument.graphics",
      "application/vnd.oasis.opendocument.image",
      "application/vnd.oasis.opendocument.text-master",
      "application/vnd.oasis.opendocument.presentation",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.oasis.opendocument.graphics-template",
      "application/vnd.oasis.opendocument.text-web",
      "application/vnd.oasis.opendocument.presentation-template",
      "application/vnd.oasis.opendocument.spreadsheet-template",
      "application/vnd.oasis.opendocument.text-template",
      "application/ogg",
      "application/xslt+xml",
      "application/vnd.mozilla.xul+xml",
      "application/vnd.visio",
      "text/vnd.wap.wml",
      "application/vnd.wap.wmlc",
      "text/vnd.wap.wm",
      "application/vnd.wap.wmlscriptc",
      "application/font-woff",
      "application/font-woff2",
      "application/xml-dtd",
      "application/x-dvi",
      "application/vnd.ms-fontobject",
      "text/x-setext",
      "application/x-gtar",
      "application/x-gzip",
      "application/x-hdf",
      "application/mac-binhex40",
      "text/x-component",
      "text/vnd.sun.j2me.app-descriptor",
      "application/java-archive",
      "text/x-java-source",
      "application/x-java-jnlp-file",
      "application/javascript",
      "text/plain",
      "application/json",
      "application/x-latex",
      "application/x-font-opentype",
      "application/annodex",
      "application/xspf+xml",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.rn-realmedia",
      "application/rtf",
      "text/richtext",
      "application/font-sfnt",
      "application/x-sh",
      "application/x-shar",
      "application/x-stuffit",
      "application/x-sv4cpio",
      "application/x-sv4crc",
      "application/x-shockwave-flash",
      "application/x-tar",
      "application/x-tcl",
      "application/x-tex",
      "application/x-texinfo",
      "text/tab-separated-values",
      "application/x-font-ttf",
      "application/x-ustar",
      "application/voicexml+xml",
      "application/xhtml+xml",
      "application/vnd.ms-excel",
      "application/xml",
      "text/troff",
      "application/mathml+xml",
      "application/x-mif",
    ],

    validImageTypes: [
    "image/jpeg",
      "image/png"
    ],
    validVideoTypes: [
    "video/mp4",
      "video/3gpp",
    ],
    validAudioTypes: [
    "audio/aac",
      "audio/mp4",
      "audio/amr",
      "audio/mpeg",
      "audio/ogg; codecs=opus",
    ],

    rules: [
      value => {
        console.log({
          value
        })
        let validDocumentTypes= [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/wspolicy+xml",
      "application/x-compress",
      "application/zip",
      "application/postscript",
      "application/x-aim",
      "application/x-bcpio",
      "application/octet-stream",
      "text/html",
      "application/x-cdf",
      "application/pkix-cert",
      "application/java",
      "application/x-cpio",
      "application/x-csh",
      "text/css",
      "application/msword",
      "application/x-wais-source",
      "application/x-netcdf",
      "application/oda",
      "application/vnd.oasis.opendocument.database",
      "application/vnd.oasis.opendocument.chart",
      "application/vnd.oasis.opendocument.formula",
      "application/vnd.oasis.opendocument.graphics",
      "application/vnd.oasis.opendocument.image",
      "application/vnd.oasis.opendocument.text-master",
      "application/vnd.oasis.opendocument.presentation",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.oasis.opendocument.graphics-template",
      "application/vnd.oasis.opendocument.text-web",
      "application/vnd.oasis.opendocument.presentation-template",
      "application/vnd.oasis.opendocument.spreadsheet-template",
      "application/vnd.oasis.opendocument.text-template",
      "application/ogg",
      "application/xslt+xml",
      "application/vnd.mozilla.xul+xml",
      "application/vnd.visio",
      "text/vnd.wap.wml",
      "application/vnd.wap.wmlc",
      "text/vnd.wap.wm",
      "application/vnd.wap.wmlscriptc",
      "application/font-woff",
      "application/font-woff2",
      "application/xml-dtd",
      "application/x-dvi",
      "application/vnd.ms-fontobject",
      "text/x-setext",
      "application/x-gtar",
      "application/x-gzip",
      "application/x-hdf",
      "application/mac-binhex40",
      "text/x-component",
      "text/vnd.sun.j2me.app-descriptor",
      "application/java-archive",
      "text/x-java-source",
      "application/x-java-jnlp-file",
      "application/javascript",
      "text/plain",
      "application/json",
      "application/x-latex",
      "application/x-font-opentype",
      "application/annodex",
      "application/xspf+xml",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.rn-realmedia",
      "application/rtf",
      "text/richtext",
      "application/font-sfnt",
      "application/x-sh",
      "application/x-shar",
      "application/x-stuffit",
      "application/x-sv4cpio",
      "application/x-sv4crc",
      "application/x-shockwave-flash",
      "application/x-tar",
      "application/x-tcl",
      "application/x-tex",
      "application/x-texinfo",
      "text/tab-separated-values",
      "application/x-font-ttf",
      "application/x-ustar",
      "application/voicexml+xml",
      "application/xhtml+xml",
      "application/vnd.ms-excel",
      "application/xml",
      "text/troff",
      "application/mathml+xml",
      "application/x-mif",
    ];

    let validImageTypes = [
    "image/jpeg",
      "image/png"
    ];
    let validVideoTypes= [
    "video/mp4",
      "video/3gpp",
    ];
    let validAudioTypes= [
    "audio/aac",
      "audio/mp4",
      "audio/amr",
      "audio/mpeg",
      "audio/ogg; codecs=opus",
    ];

        for(let _idx = 0; _idx < value.length; _idx++ ){
          if(validImageTypes.includes(value[_idx].type))
            return !value || !value.length || value[_idx].size < 5000000 || 'Image size should be less than 5 MB!';
          else if(validVideoTypes.includes(value[_idx].type)){
            console.log({
              type: value[_idx].type,
              size: value[_idx].size,
              isValid: value[_idx].size < 16000000
            });
            return !value || !value.length || value[_idx].size < 16000000 || 'video size should be less than 16 MB!'

          }
          else if(validAudioTypes.includes(value[_idx].type))
            return !value || !value.length || value[_idx].size < 16000000 || 'audio size should be less than 16 MB!'
          else if(validDocumentTypes.includes(value[_idx].type))
            return !value || !value.length || value[_idx].size < 100000000 || 'document size should be less than 100 MB!'
        }
        // value.forEach(val => {
        //   console.log({
        //     val
        //     })
        //   if(validImageTypes.includes(val.type))
        //     return !value || !value.length || val.size < 5000000 || 'Image size should be less than 5 MB!';
        //   else if(validVideoTypes.includes(val.type)){
        //     console.log({
        //       type: val.type
        //     })
        //     return !value || !value.length || val.size < 16000000 || 'video size should be less than 16 MB!'

        //   }
        //   else if(validAudioTypes.includes(val.type))
        //     return !value || !value.length || val.size < 16000000 || 'audio size should be less than 16 MB!'
        //   else if(validDocumentTypes.includes(val.type))
        //     return !value || !value.length || val.size < 100000000 || 'document size should be less than 100 MB!'
        // });
        
      },
    ],

    is_template: false,
    variables: [],
    header: [],
    language: null,
  }),
  computed: {
    ...mapState(useUserStore, [
      'userList',
      'user',
      'admin',
      'filterUsers',
      'instagramUsers',
      'whatsappUsers',
      'isUserHasMoreData'
    ]),
    ...mapState(useTemplateStore, ['templates', 'templatesKeys']),
  },

  methods: {
    ...mapActions(useUserStore, ['getUser', 'getAdmin', 'sendMessage', 'getMessages', 'sendAttachment']),
    ...mapActions(useTemplateStore, ['getTemplates']),
    sendFilesHandler: async function () {
      this.dialog = false
      const form_data = new FormData()
      let msgData = [];

      for (let idx = 0; idx < this.files.length; idx++) {
        let payload = {
          client_id: uuidv4(),
          idx,
          content: `${await this.getAttchmentType(this.files[idx].type)} Message`,
          platform: this.platform,
          parentMsg: this.isReply ? this.replyMsgParent.wa_msg_id : null,
          type: `${await this.getAttchmentType(this.files[idx].type)}`,
          status: 'pending',
          seen: false,
          from: this.admin['id'],
          createdAt: new Date(),
          to: this.to,
        }

        console.log({
          payload,
        })

        // await this.$store.dispatch("ADD_MESSAGE", payload);

        form_data.append('files[]', this.files[idx]);
        form_data.append('to', this.to);
        form_data.append('client_id', payload.client_id);

        msgData.push(payload);
      }
      // const config = {
      //   headers: {
      //     'content-type': 'multipart/form-data',
      //   },
      // }
      this.loading = true
      this.sendAttachment(form_data, this.files, msgData, this.platform);
      this.loading = false
      // const res = await axios.post('messages/attachment', form_data, config)

      // console.log('sendFilesHandler ==> ', { res })

      // if (this.CURRENT_USER) {
      //   for (let i = 0; i < this.files.length; i++) {
      //     this.user['messages'].forEach((msg) => {
      //       if (msg.idx === i) {
      //         console.log('< ========= >', {
      //           msg,
      //           i,
      //         })
      //         msg._id = res.data.data[i]._id
      //         msg.idx = ''
      //       }
      //     })
      //   }
      // }
    },
    downHandler: function () {
      this.scrollToEnd()
    },
    onClickButton: function (image_path) {
      console.log('onClickButton')
      this.image_pop_up = true
      this.image_path = image_path
    },
    handleDocument: function (link) {
      console.log('handleDocument')
      window.open(link, '_blank');

    },
    scheduleMsgHandler: function(){
      this.is_scheduled = true;
    },
    cancelScheduleMsgHandler: function(){
      this.is_scheduled = false;
    },
    handleScroll: async function (e) {
      console.log({ e })
      const height = e.srcElement.offsetHeight
      const top = e.srcElement.scrollTop

      const scrollHeight = e.srcElement.scrollHeight

      const plus = top + height

      const flag = plus >= scrollHeight

      if (e.srcElement.scrollTop === 0) {
        this.isScroll = true
        this.isDown = true
        this.pageNumber += 1

        if (this.isUserHasMoreData)
          this.getMessages(this.platform, this.to, this.pageNumber, 10);

      } else if (flag) {
        this.isDown = false
        this.isScroll = true
      } else {
        this.isScroll = false
        this.isDown = true
      }
    },
    momentFilter: function (date) {
      return moment(date).format('MMMM Do YYYY, h:mm a')
    },
    scrollToSectionHandler: function (id) {
      console.log({
        id,
      })

      const el = document.getElementById(id)
      el.scrollIntoView({ inline: 'nearest' })
    },
    async sendMsg() {
     

      let payload = {};

if(!this.is_template){
  payload = {
  client_id: uuidv4(),
  content: this.message,
  platform: this.platform,
  parent: this.isReply ? this.replyMsgParent : null,
  parentMsg: this.isReply ? this.replyMsgParent.wa_msg_id : null,
  scheduleAt : this.is_scheduled ? momentTZ(this.scheduleAt).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'): null,
  is_scheduled: this.is_scheduled,
  type: 'text',
  status: 'pending',
  seen: false,
  from: this.admin['id'],
  createdAt: new Date(),
  to: this.to,
}
}else{
  payload = {
      client_id: uuidv4(),
      content: this.template + ' template message',
      platform: this.platform,
      type: this.template.replace(`_${this.language}`, ''),
      seen: false,
      messageType: 'template',
      from: this.admin['id'],
      status: 'pending',
      to: this.to,
      products: this.selected,
      users: [this.to],
      variables: this.variables,
      header: this.header,
      language: this.language,
      createdAt: new Date(),
      scheduleAt: this.is_scheduled ? momentTZ(this.scheduleAt).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
      is_scheduled: this.is_scheduled,
    }
}

      console.log({
        payload,
      })

      // await this.$store.dispatch('POST_MESSAGE', payload)
      if (this.message !== '' || this.is_template) {
        this.loading = true
        this.sendMessage(payload, this.platform);

        this.loading = false

        this.message = '';
        if(this.is_scheduled){
          this.is_scheduled = false;
          this.scheduleAt = momentTZ().format('YYYY-MM-DDTHH:mm')
        }
        if(this.is_template){
          this.is_template = false;
          this.template = null;
        }
        
        this.replyMsgParent = {};
        this.isReply = false

        this.scrollToEnd()
      }
    },

    scrollToEnd: function () {
      // if (!this.isScroll && this.down)
      // let container = this.$el.querySelector("#msg-window");
      // this.$refs.list.scrollTop = this.$refs.list.scrollHeight + 3000
      if (this.user?.messages.length > 0)
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight + 3000

      // container.scrollTop = container.scrollHeight;
      // this.isDown = false

      // else this.$refs.list.scrollTop = 10;
    },
    closeReplyMsgHandler: function () {
      console.log('closeReplyMsgHandler')
      this.isReply = false
    },
    replyHandler: function (msg) {
      this.replyMsgParent = msg
      this.isReply = true
      console.log({
        msg,
        isReply: this.isReply,
      })
    },

    getAttchmentType: async function (mimeType) {
      console.log({
        mimeType
      })
      const FileMimeType = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'document',
        'application/wspolicy+xml': 'document',
        'application/x-compress': 'document',
        'application/zip': 'document',
        'application/postscript': 'document',
        'application/x-aim': 'document',
        'application/x-bcpio': 'document',
        'application/octet-stream': 'document',
        'text/html': 'document',
        'application/x-cdf': 'document',
        'application/pkix-cert': 'document',
        'application/java': 'document',
        'application/x-cpio': 'document',
        'application/x-csh': 'document',
        'text/css': 'document',
        'application/msword': 'document',
        'application/x-wais-source': 'document',
        'application/x-netcdf': 'document',
        'application/oda': 'document',
        'application/vnd.oasis.opendocument.database': 'document',
        'application/vnd.oasis.opendocument.chart': 'document',
        'application/vnd.oasis.opendocument.formula': 'document',
        'application/vnd.oasis.opendocument.graphics': 'document',
        'application/vnd.oasis.opendocument.image': 'document',
        'application/vnd.oasis.opendocument.text-master': 'document',
        'application/vnd.oasis.opendocument.presentation': 'document',
        'application/vnd.oasis.opendocument.spreadsheet': 'document',
        'application/vnd.oasis.opendocument.text': 'document',
        'application/vnd.oasis.opendocument.graphics-template': 'document',
        'application/vnd.oasis.opendocument.text-web': 'document',
        'application/vnd.oasis.opendocument.presentation-template': 'document',
        'application/vnd.oasis.opendocument.spreadsheet-template': 'document',
        'application/vnd.oasis.opendocument.text-template': 'document',
        'application/ogg': 'document',
        'application/xslt+xml': 'document',
        'application/vnd.mozilla.xul+xml': 'document',
        'application/vnd.visio': 'document',
        'text/vnd.wap.wml': 'document',
        'application/vnd.wap.wmlc': 'document',
        'text/vnd.wap.wmlsc': 'wmls',
        'application/vnd.wap.wmlscriptc': 'document',
        'application/font-woff': 'document',
        'application/font-woff2': 'document',
        'application/xml-dtd': 'document',
        'application/x-dvi': 'document',
        'application/vnd.ms-fontobject': 'document',
        'text/x-setext': 'document',
        'application/x-gtar': 'document',
        'application/x-gzip': 'document',
        'application/x-hdf': 'document',
        'application/mac-binhex40': 'document',
        'text/x-component': 'document',
        'text/vnd.sun.j2me.app-descriptor': 'document',
        'application/java-archive': 'document',
        'text/x-java-source': 'document',
        'application/x-java-jnlp-file': 'document',
        'application/javascript': 'document',
        'text/plain': 'document',
        'application/json': 'document',
        'application/x-latex': 'document',
        'application/x-font-opentype': 'document',
        'application/annodex': 'document',
        'application/xspf+xml': 'document',
        'application/pdf': 'document',
        'application/vnd.ms-powerpoint': 'document',
        'application/rdf+xml': 'document',
        'application/vnd.rn-realmedia': 'document',
        'application/rtf': 'document',
        'text/richtext': 'document',
        'application/font-sfnt': 'document',
        'application/x-sh': 'document',
        'application/x-shar': 'document',
        'application/x-stuffit': 'document',
        'application/x-sv4cpio': 'document',
        'application/x-sv4crc': 'document',
        'application/x-shockwave-flash': 'document',
        'application/x-tar': 'document',
        'application/x-tcl': 'document',
        'application/x-tex': 'document',
        'application/x-texinfo': 'document',
        'text/tab-separated-values': 'document',
        'application/x-font-ttf': 'document',
        'application/x-ustar': 'document',
        'application/voicexml+xml': 'document',
        'application/xhtml+xml': 'document',
        'application/vnd.ms-excel': 'document',
        'application/xml': 'document',
        'text/troff': 'document',
        'application/mathml+xml': 'document',
        'application/x-mif': 'document',

        'audio/x-mpeg': 'audio',
        'audio/x-aiff': 'audio',
        'audio/basic': 'audio',
        'audio/midi': 'audio',
        'audio/x-mpegurl': 'audio',
        'audio/mpeg': 'audio',
        'audio/ogg': 'audio',
        'audio/flac': 'audio',
        'audio/annodex': 'audio',
        'audio/x-scpls': 'audio',
        'audio/x-wav': 'audio',

        'image/x-jg': 'image',
        'image/bmp': 'image',
        'image/gif': 'image',
        'image/ief': 'image',
        'image/jpeg': 'image',
        'image/svg+xml': 'image',
        'image/tiff': 'image',
        'image/x-xbitmap': 'image',
        'image/x-xpixmap': 'image',
        'image/x-xwindowdump': 'image',
        'image/vnd.wap.wbmp': 'image',
        'image/x-portable-bitmap': 'image',
        'image/pict': 'image',
        'image/x-portable-graymap': 'image',
        'image/png': 'image',
        'image/x-portable-anymap': 'image',
        'image/x-portable-pixmap': 'image',
        'image/vnd.adobe.photoshop': 'image',
        'image/x-quicktime': 'image',
        'image/x-cmu-raster': 'image',
        'image/x-rgb': 'image',
        'image/x-macpaint': 'image',

        'video/x-ms-asf': 'video',
        'video/x-msvideo': 'video',
        'video/x-rad-screenplay': 'video',
        'video/x-dv': 'video',
        'video/quicktime': 'video',
        'video/x-sgi-movie': 'video',
        'video/mp4': 'video',
        'video/mpeg': 'video',
        'video/mpeg2': 'video',
        'video/ogg': 'video',
        'video/annodex': 'video',
        'video/x-ms-wmv': 'video',
      }

      return FileMimeType[mimeType]
    },

    templateChanged(value) {
      this.variables = []
      this.header = []
      this.language = null
      console.log('template changed - ', value)


      if (value && value !== 'sample_test') {
        const data = this.templates[value]
        this.language = this.templates[value].language
        console.log('template changed - ', { data })

        data['components'].forEach((item) => {
          console.log({
            item,
          })
          if (item.type === 'BODY') {
            if (item?.example?.body_text) {
              this.variables = item?.example?.body_text[0]
              console.log({
                v: this.variables,
              })
            }
          }
          if (item.type === 'HEADER') {
            let format = item.format.toLowerCase();

            if (item.example?.header_handle) {
              this.header.push({
                type: format,
              })

              this.header[0][format] = {
                id: data['assets']['id'],
              }

              if (item.format == 'DOCUMENT') {
                this.header[0][format]['filename'] = 'Document'
              }
            }
            else if (format === 'location') {
              this.header.push({
                type: format,
              })
              this.header[0][format] = data['assets']

            }
          }
          this.is_template = true;
          console.log({ header: this.header })
        })
      }
    },
    openSelect() {
      this.$refs.templateSelect.menu = true;
    },
    formatMessage(content) {
      // Replace \n with <br> to display multiline text
      return content.replace(/\n/g, '<br>');
    },
    clearSelect:  async function(){
      this.template = null;
      this.is_template = false;
    },
    isNumeric: function  (str) {
      return /^[0-9]+$/.test(str);
    }
  },

  async updated() {
    this.id = this.$route.params.id
    this.platform = this.$route.query.platform
    console.log('Conversation | updated', {
      id: this.$route.params.id,
    })
    this.pageNumber = 1;

    const user = await this.getUser(this.id)
    // this.getAdmin()

    this.to = this.$route.params.id
    this.scrollToEnd()
    console.log({
      user,
    });

    // if (this.CURRENT_USER) {
    //   if (this.CURRENT_USER["messages"].length > 0) {
    //     if (!this.isFristScroll) {
    //       this.$nextTick(() => this.scrollToEnd());
    //       this.isFristScroll = true;
    //     }
    //   }
    // }
  },
  async mounted() {
    await this.getAdmin();
    await this.getTemplates()

    console.log('Conversation | mounted', {
      id: this.$route.params.id,
    })

    this.id = this.$route.params.id
    this.platform = this.$route.query.platform
    this.getUser(this.id);

    console.log({
      a: this.admin['id']
    })
    this.to = this.$route.params.id
    console.log({ to: this.to })
    this.pageNumber = 1;
    this.scrollToEnd()
    // if (this.CURRENT_USER) {
    //   if (this.CURRENT_USER["messages"].length > 0) {
    //     this.$nextTick(() => this.scrollToEnd());
    //   }
    // }
    // await this.$store.dispatch("UPDATE_USER", null);

    // if (
    //   this.$route.query.type === undefined ||
    //   !this.platforms.includes(this.$route.query.type)
    // )
    //   this.$router.push({ name: "Home" });

    // console.log("mounted", {
    //   id: this.$route.params.id,
    //   type: this.$route.query.type,
    // });

    // if (this.platforms.includes(this.$route.query.type)) {
    //   await this.$store.dispatch("GET_USER", {
    //     id: this.$route.params.id,
    //     page: 1,
    //     limit: 10,
    //   });
    //   await this.$store.dispatch("SET_PLATFORM", this.$route.query.type);

    //   this.to = this.$route.params.id;
    //   this.type = this.$route.query.type;
    // }
  },
}
</script>
